<template>
  <div class="nav-bar" :class="{ showBack }">
    <van-nav-bar
      :class="{ fixed, 'nav-bar-inner': true }"
      :style="`background-color: ${bg};`"
    >
      <template #left>
        <van-icon
          v-if="showBack"
          name="arrow-left"
          size="18"
          color="#333333"
          @click="handleBack"
        />
        <span
          v-html="title || $route.meta.title"
          class="nav-title"
          @click="handleBack"
        ></span>
      </template>
      <template #right>
        <slot name="right"> </slot>
      </template>
    </van-nav-bar>
    <div v-if="fixed" class="placeholder"></div>
  </div>
</template>

<script>
import { NavBar, Icon } from "vant";
export default {
  name: "Navbar",
  props: {
    showBack: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    bg: {
      type: String,
      default: "#ffffff"
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "van-nav-bar": NavBar,
    "van-icon": Icon
  },
  methods: {
    handleBack() {
      window.history.length > 1
        ? this.$router.back()
        : this.$router.replace("/h5/home");
    }
  }
};
</script>

<style lang="scss">
.nav-bar {
  z-index: 1;

  .placeholder {
    /* prettier-ignore */
    height: 46PX;
    width: 100%;
  }

  &.showBack {
    .van-nav-bar__left {
      padding-left: 30px;
    }
  }
}
.nav-bar-inner {
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &::after {
    border: none;
  }
}
.nav-title {
  font-size: 60px;
}
</style>
