<template>
  <div class="search">
    <navbar :showBack="false" />
    <div class="search-wrap">
      <div class="exact-search">
        <input
          :type="item.key === 'price' ? 'number' : 'text'"
          v-for="item in searchKeys"
          :key="item.key"
          v-model="query[item.key]"
          :placeholder="`请输入${item.label}`"
        />
        <van-uploader
          v-if="query.image"
          v-model="query.image"
          :max-count="1"
          :after-read="uploadFile"
        />
        <textarea v-model="query.content" placeholder="请输入内容" />
        <van-button class="search-btn" @click="handleSearch()">
          立即发布
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Button, Uploader } from "vant";
import Navbar from "@/components/h5/Navbar.vue";
import otherApis from "@/apis/other";
import { apiRoot } from "@/config";
import axios from "axios";

const searchKeys = [
  {
    label: "任务主题",
    key: "title"
  },
  {
    label: "摘要",
    key: "description"
  },
  {
    label: "联系人",
    key: "contact"
  },
  {
    label: "联系电话",
    key: "telephone"
  },
  {
    label: "电子邮箱",
    key: "email"
  },
  {
    label: "悬赏金额",
    key: "price"
  }
];

export default {
  name: "Search",
  components: {
    // "van-search": Search,
    // "van-icon": Icon,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    Navbar
  },
  data() {
    return {
      value: "",
      history: new Set(),
      searchKeys,
      showSelect: false,
      query: {
        title: "",
        description: "",
        contact: "",
        telephone: "",
        email: "",
        price: "",
        content: "",
        image: []
      }
      // matchedIndex: -1
    };
  },
  computed: {
    uploadData() {
      const auth = localStorage.getItem("auth") || "{}";
      const { token } = JSON.parse(auth);
      return { token };
    }
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    fillSrc(src) {
      if (src.indexOf("http") >= 0) return src;
      return `${apiRoot}${src}`;
    },

    handleCancel() {
      console.log(history);
      if (history.length <= 2) {
        this.$router.replace("/h5/home");
      } else {
        this.$router.back();
      }
    },

    async fetchDetail() {
      if (!this.$route.query.id) return;
      console.log("fetch detail", this.$route.query.id);
      this.loading = true;
      try {
        const {
          data: { article_info }
        } = await otherApis.articleDetail({
          id: this.$route.query.id
        });

        this.query = {
          ...this.query,
          ...article_info
        };
        if (article_info.image) {
          this.query = {
            ...this.query,
            image: [{ url: article_info.image, isImage: true }]
          };
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async handleSearch() {
      const query = {
        ...this.query,
        image: this.query.image?.[0]?.url.replace(apiRoot, "")
      };
      try {
        await otherApis.articleAdd({ ...query, id: this.$route.query.id });
        this.$toast("提交成功，请等待管理员审核");
        this.$router.back();
      } catch (error) {
        console.log("error", error);
        // this.$refs["pay-dialog"].show();
      }
    },

    uploadFile(file) {
      const form = new FormData();
      form.append("avatar", file.file);
      form.append("token", this.uploadData.token);
      axios.post(`${apiRoot}/user/upload_avatar`, form).then(
        ({
          data: {
            data: { avatar_url }
          }
        }) => {
          this.query.image = [{ url: this.fillSrc(avatar_url), isImage: true }];
        }
      );
    }
  }
};
</script>

<style lang="scss">
.van-search__action {
  font-size: 44px;
  color: #0ab0fb;
}
</style>

<style lang="scss" scoped>
.search {
  height: calc(100vh - 158px);
  background-color: #fff;
  overflow: auto;
}
.search-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .normal-search {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    top: -200px;

    .logo {
      height: 150px;
      object-fit: contain;
    }

    .search-input {
      display: flex;
      align-items: center;
      margin-top: 100px;

      input {
        width: 800px;
        height: 120px;
        background: #f7f7f7;
        border: 1px solid #f0f0f0;
        border-radius: 10px;
        font-size: 36px;
        color: #666;
        box-sizing: border-box;
        margin-right: 30px;
        text-align: center;
      }

      .search-btn {
        width: 210px;
        height: 120px;
        background: #ca0a15;
        border-radius: 10px;
        color: #fff;
      }
    }

    .exact-search-btn {
      width: 1045px;
      height: 120px;
      border: 1px solid #ca0a15;
      border-radius: 10px;
      color: #ca0a15;
      font-size: 36px;
      margin-top: 50px;
    }
    // height: 100%;
  }

  .exact-search {
    display: flex;
    flex-flow: column nowrap;
    // margin-top: -100px;

    input,
    textarea {
      width: 1045px;
      height: 120px;
      padding: 40px;
      box-sizing: border-box;
      background: #f7f7f7;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      font-size: 36px;
      margin-bottom: 30px;
    }

    textarea {
      height: 500px;
    }

    .search-btn {
      width: 1045px;
      height: 120px;
      background: #ca0a15;
      border-radius: 10px;
      color: #fff;
    }
  }
}
</style>
